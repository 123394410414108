import { useRef, useEffect, useState } from 'react';
import { Block } from 'types/page';
import { ReactComponent as GlobeIcon } from 'Assets/globe.svg';
import { ReactComponent as ArrowLeft } from 'Assets/arrow-left-back.svg';
import { ReactComponent as ChainIcon } from 'Assets/chain.svg';
import { PrimitiveTypes } from '../types';
import Selection from '../Common/Selection/Selection';

import styles from './NavigationSettings.module.scss';
import { graphQlCall } from 'graphql/utils';
import QUERIES from 'graphql/queries';

interface IProps {
  block: Block;
  onValueChange: (attributeId: string, value: PrimitiveTypes) => void;
}

interface IOptions {
  label: string;
  value: string;
}

const NavigationSettings = ({ block, onValueChange }: IProps) => {
  const inputRef: React.LegacyRef<HTMLInputElement> = useRef(null);
  const [userProducts, setUserProducts] = useState<IOptions[]>([]); 

  useEffect(() => {
    if (block.userProduct) {
      fetchProducts();
    }
  }, [block.userProduct]);

  const fetchProducts = async () => {
    const products: any[] = await graphQlCall({
      queryTemplateObject: QUERIES.GET_USER_PRODUCTS,
      headerType: 'USER-AUTH'
    });
    const options: IOptions[] = products.map(product => ({
      value: product._id,
      label: `${product.name} ${product.price} ${product.currency}`
    }));
    setUserProducts(options);
  }

  return (
    <div className={styles.container}>
      <Selection
        options={block.destination.options}
        selectedValue={block.destination.value}
        label={
          <div className={styles.selectionLabel}>
            <GlobeIcon /> Destination:
          </div>
        }
        onOptionSelect={(newValue) => onValueChange('destination', newValue)}
      />
      {block.destination.value !== 'upsell' && <Selection
        options={block.targetType.options}
        selectedValue={block.targetType.value}
        label={
          <div className={styles.selectionLabel}>
            <ArrowLeft className={styles.mirror} /> Open As
          </div>
        }
        onOptionSelect={(newValue) => onValueChange('targetType', newValue)}
      />}
      {block.userProduct && block.destination.value === 'upsell' && <Selection
        options={userProducts}
        selectedValue={block.selectedUserProductId.value}
        label={
          <div className={styles.selectionLabel}>
            <ArrowLeft className={styles.mirror} /> Product
          </div>
        }
        onOptionSelect={(newValue) => onValueChange('selectedUserProductId', newValue)}
        //onOptionSelect={(newValue) => console.log('new value', newValue)}
      />}
      {block.url.visible && (
        <div
          className={styles.urlInputContainer}
          onClick={() => {
            if (inputRef.current) inputRef.current.focus();
          }}
        >
          <ChainIcon />
          https://
          <input
            ref={inputRef}
            value={block.url.value?.replaceAll('https://', '')}
            onChange={(e) => {
              const newValue =
                'https://' + e.target.value.replaceAll('https://', '');
              onValueChange('url', newValue);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default NavigationSettings;
